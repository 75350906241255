import { AUTH_USERS } from '../constants';

const getUserIdLogin = () => {
  const authUser = JSON.parse(localStorage.getItem(AUTH_USERS)) || [];
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userId = urlParams.get('u');
  return (
    authUser[userId && !(Number(userId) > Number(authUser.length - 1)) ? Number(userId) : 0]?.id ||
    ''
  );
};

export default getUserIdLogin;
