import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Badge } from 'antd';
import { FileOutlined, IssuesCloseOutlined, KeyOutlined, UserOutlined } from '@ant-design/icons';
import axiosInstance from '../../plugins/axios';
import './SidebarTopMenu.css';
import {
  ACCESS_TOKEN_KEY,
  ERROR_CODE_BAD_REQUEST,
  REFRESH_TOKEN_KEY,
  USER_ODA,
  USER_VINA,
  CURRENT_PAGE,
} from '../../constants';
import Cookies from 'js-cookie';

function SidebarTopMenu({ className }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [itemUnread, setItemUnread] = useState(0);

  const path = location.pathname.split('/')[1].trim();

  const fetchData = async () => {
    try {
      const res = await axiosInstance.get('/portal/count-item-unread');
      if (res) {
        setItemUnread(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!window.location.hash.includes('files')) {
      sessionStorage.removeItem(CURRENT_PAGE);
    }
    setTimeout(() => fetchData(), 1000);
  }, [location.pathname]);

  const items = [
    { key: '/files', label: t('My Files'), icon: <FileOutlined /> },
    {
      key: '/feedbacks',
      label: t('My Feedbacks'),
      icon: <IssuesCloseOutlined />,
      badge: itemUnread === 0 ? '' : itemUnread > 0 && itemUnread < 99 ? itemUnread : '99+',
    },
    {
      key: '/licenses',
      label: t('My Licenses'),
      icon: <KeyOutlined />,
    },
    {
      key: '/profile',
      label: t('My Profile'),
      icon: <UserOutlined />,
    },
  ];

  const menuItems = items.map((item) => ({
    key: item.key,
    icon: item.icon,
    label: (
      <>
        {item.label}
        {item.key === '/feedbacks' && item.badge && (
          <Badge className="new-badge" count={item.badge} />
        )}
      </>
    ),
    onClick: () => navigate(item.key),
  }));

  return (
    <Menu
      className={className}
      selectedKeys={[`/${path}`]}
      mode="inline"
      theme="dark"
      items={menuItems}
    />
  );
}

export default SidebarTopMenu;
