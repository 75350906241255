import React from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { Layout } from 'antd';
import { withSuspense } from '../../components';
import Error404 from '../../views/Error404';
import Sidebar from './Sidebar';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, USER_ODA } from '../../constants';
import getUserIdLogin from '../../utils/getUserIdLogin';

const ViewerPage = withSuspense(React.lazy(() => import('../../viewer/ViewerPage')));
const AutodeskViewerPage = withSuspense(
  React.lazy(() => import('../../viewer/AutodeskViewerPage'))
);
const Files = withSuspense(React.lazy(() => import('../../views/Files')));
const Feedbacks = withSuspense(React.lazy(() => import('../../views/Feedbacks')));
const FeedbackDetail = withSuspense(
  React.lazy(() => import('../../views/Feedbacks/FeedbackDetail'))
);
const Licenses = withSuspense(React.lazy(() => import('../../views/Licenses')));
const LicenseDetail = withSuspense(React.lazy(() => import('../../views/Licenses/LicenseDetail')));
const Profile = withSuspense(React.lazy(() => import('../../views/Profile')));
const { Content } = Layout;

function SidebarLayout() {
  return (
    <Layout className="min-vh-100 bg-white">
      <Sidebar theme="light" active="Files"></Sidebar>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
}

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const userId = urlParams.get('u');
const data = {
  access: Cookies.get(`${ACCESS_TOKEN_KEY}_${getUserIdLogin()}`),
  refresh: Cookies.get(`${REFRESH_TOKEN_KEY}_${getUserIdLogin()}`),
  oda: JSON.parse(localStorage.getItem(`${USER_ODA}_${getUserIdLogin()}`)),
  userId,
};
let path = window.location.pathname;
if (path === '/') {
  path = '/files';
}

function DefaultLayout() {
  return (
    <Routes>
      <Route element={<SidebarLayout />}>
        <Route index element={<Navigate to={path} replace />} />
        <Route path="files">
          <Route index element={<Files data={data} />} />
          <Route path=":fileId" element={<ViewerPage />} />
          <Route path=":fileId/:fileName" element={<AutodeskViewerPage />} />
        </Route>
        <Route path="feedbacks">
          <Route index element={<Feedbacks />} />
          <Route path=":feedbackId" element={<FeedbackDetail />} />
        </Route>
        <Route path="licenses">
          <Route index element={<Licenses />} />
          <Route path=":licenseId" element={<LicenseDetail />} />
        </Route>
        <Route path="profile">
          <Route index element={<Profile />} />
        </Route>
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export default DefaultLayout;
